import * as React from 'react';
import styled from 'styled-components';
import Logo from '../../../images/karla_logo.svg';
import KarlaX from '../../../images/karla_x.svg';
import merchants from '../../../merchants.json';

const StyledHeader = styled.header`
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 105px;
`;

const StyledKarlaX = styled(KarlaX)`
  width: 24px;
  height: 24px;
`;

const StyledMerchantLogo = styled.img`
  width: 40%;
  max-width: 150px;
  max-height: 65px;
`;

const StyledKarlaLogo = styled(Logo)`
  width: 40%;
  max-width: 150px;
  max-height: 90px;
`;

function Header({ merchant }) {
  const merchantConfig = merchants[merchant] || {};
  const { logoUrls = {} } = merchantConfig;

  return (
    <StyledHeader>
      <StyledMerchantLogo src={logoUrls.white} />
      <StyledKarlaX />
      <StyledKarlaLogo />
    </StyledHeader>
  );
}

export default Header;
