import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SocialLinks from '../../SocialLinks';

// flex-end - always be at bottom of page
const StyledFooter = styled.footer`
  align-self: flex-end;
  width: 100%;
  background-color: #CCD1D9;
  margin: 0px;
  padding-bottom: 32px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
`;

const StyledText = styled.p`
  font-size: 14px;
  margin: 0px;
`;

function Footer() {
  const { t } = useTranslation();
  return (
    <StyledFooter>
      <StyledDiv>
        <SocialLinks showLogo={false} />
      </StyledDiv>
      <StyledDiv>
        <StyledText>
          {t('footer.disclaimer')}
        </StyledText>
      </StyledDiv>
    </StyledFooter>
  );
}

export default Footer;
